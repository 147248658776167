.LabeledTextInput input,
.LabeledDatePicker input,
.LabeledTextArea textarea {
	border: 1px solid #ccc;
	border-radius: 4px;
	border-left: 8px solid;
	border-left-color: var(--lw-blue);
}

.LabeledInput {
	margin-bottom: 12px;
}
.LabeledTextInput input {
	height: 38px;
}
.LabeledTextInput input.InputInvalid {
	border-left-color: var(--lw-darker-gold);
}

.LabeledDatePicker input {
	padding-left: 6px;
	height: 38px;
	width: 175px;
}

.LabeledTextArea textarea {
	padding: 4px 8px;
	resize: none;
	width: 100%;
	height: 178px;
	border-color: #ccc;
	border-left-color: var(--lw-blue);
	cursor: text;
}
.LabeledTextArea textarea:disabled {
	background-color: hsl(0, 0%, 95%);
	cursor: not-allowed;
}
.LabeledDatePicker input:focus,
.LabeledTextArea textarea:focus {
	border-color: var(--lw-focus-border);
	outline: 0;
	-webkit-box-shadow:
		inset 0 1px 1px rgba(0, 0, 0, 0.075),
		0 0 8px var(--lw-focus-box-shadow);
	box-shadow:
		inset 0 1px 1px rgba(0, 0, 0, 0.075),
		0 0 8px var(--lw-focus-box-shadow);
}
.form-group {
	margin-bottom: 0px;
}
.InputLabel {
	text-transform: uppercase;
}

@keyframes blink {
	50% {
		opacity: 0;
	}
}
@-webkit-keyframes blink {
	50% {
		opacity: 0;
	}
}

.form-control.InputInvalid:focus {
	border-color: var(--lw-darker-gold);
	outline: 0;
	-webkit-box-shadow:
		inset 0 1px 1px rgba(0, 0, 0, 0.075),
		0 0 8px rgba(var(--lw-darker-gold), 0.6);
	box-shadow:
		inset 0 1px 1px rgba(0, 0, 0, 0.075),
		0 0 8px rgba(var(--lw-darker-gold), 0.6);
}
