.HomepageTable {
	width: 100%;
}

.HomepageTable .rt-tr-group .TableButton {
	display: none;
}

.HomepageTable .rt-tr-group:hover .TableButton {
	display: inline;
}

.HomepageTable .rt-tr-group:hover .rt-td {
	position: relative;
}

.HomepageTable .IsNotFavorite,
.HomepageTable .notDeleted {
	opacity: 0;
}
.HomepageTable .rt-tr-group:hover .IsNotFavorite,
.HomepageTable .rt-tr-group:hover .notDeleted {
	opacity: 1;
}
.HomepageTable .favoriteStar,
.HomepageTable .trash {
	font-size: 1rem;
}

.HomepageTable .leftAlignedTableHeader {
	text-align: left;
}
.HomepageTable .rt-tr-group {
	flex: unset;
}
.HomepageTable .ReactTable .rt-th,
.HomepageTable .ReactTable .rt-td {
	padding-left: 10px;
	padding-right: 10px;
}

.HomepageTable .ReactTable .rt-thead .rt-th.-sort-desc,
.HomepageTable .ReactTable .rt-thead .rt-th.-sort-asc {
	box-shadow: none;
}
.HomepageTable .-sort-desc:before,
.HomepageTable .-sort-asc:before {
	position: absolute;
	font-weight: 500;
	font-size: 18px;
	right: 4px;
	top: 2px;
}

.HomepageTable .-sort-desc:before {
	content: "↓";
}
.HomepageTable .-sort-asc:before {
	content: "↑";
}
