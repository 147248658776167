:root {
	--lw-full-white: #ffffff;
	--lw-almost-white: #f5f5f5;
	--lw-light-grey-a: #f5f5f5;
	--lw-light-grey-b: #fdfdfd;
	--lw-light-grey-c: #ebebeb;
	--lw-medium-grey: #e2e2e2;
	--lw-medium-grey-b: #b2b2b2;
	--lw-darker-grey: #414141;
	--lw-dark-grey: #515151;
	--lw-full-white: #ffffff;
	--lw-dark-grey: #515151;
	--lw-light-blue: #299ff9;
	--lw-light-blue-b: #a7caed;
	--lw-pale-blue: #7092be;
	--lw-bluish-white: #f3f7ff;
	--lw-dark-blue: #337ab7;
	--lw-blue: #0082cf;
	--lw-error: #fd5351;
	--lw-darker-red: #d32f2f;
	--lw-info: #3cad2a;
	--lw-gold: #efcf00;
	--lw-darker-gold: #f39c11;
	--lw-folder-icon-yellow: #f1d592;
	--lw-focus-box-shadow: #66afe999;
	--lw-focus-border: #66afe9;
}
