.EditDialogBlocker {
	width: 100%;
	height: 100%;
	z-index: 1984;
	position: absolute;
	margin: 0px auto;
	background: rgba(100, 100, 100, 0.7);
}
.EditDialog {
	position: relative;
	width: 500px;
	margin: 160px auto;
	padding: 26px;
	background: white;
}
.EditDialog h3 {
	text-align: left;
	margin-bottom: 20px;
	margin-top: 0px;
}

.EditDialog div {
	white-space: pre-wrap;
}
.WideEditDialog {
	position: relative;
	width: 600px;
	margin: 160px auto;
	padding: 26px;
	background: white;
}
.WideEditDialog h3 {
	text-align: left;
	margin-bottom: 20px;
	margin-top: 0px;
}
