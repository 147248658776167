button.buttonLink:hover {
	color: #23527c;
	text-decoration: underline;
}
button.buttonLink {
	background: none;
	border: none;
	padding: 0;
	color: #337ab7;
	text-decoration: none;
	cursor: pointer;
	display: contents;
}
