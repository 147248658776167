.SortableTable {
	width: 100%;
}

.SortableTable .rt-tr-group .TableButton {
	display: none;
}

.SortableTable .rt-tr-group:hover .TableButton {
	display: inline;
}

.SortableTable .rt-tr-group:hover .rt-td {
	position: relative;
}

.SortableTable .IsNotFavorite,
.SortableTable .notDeleted {
	opacity: 0;
}
.SortableTable .rt-tr-group:hover .IsNotFavorite,
.SortableTable .rt-tr-group:hover .notDeleted {
	opacity: 1;
}
.SortableTable .favoriteStar,
.SortableTable .trash {
	font-size: 1rem;
}

.SortableTable .leftAlignedTableHeader {
	text-align: left;
}
.SortableTable .rt-tr-group {
	flex: unset;
}
.SortableTable .ReactTable .rt-th,
.SortableTable .ReactTable .rt-td {
	padding-left: 10px;
}

.SortableTable .ReactTable .rt-thead .rt-th.-sort-desc,
.SortableTable .ReactTable .rt-thead .rt-th.-sort-asc {
	box-shadow: none;
}
.SortableTable .-sort-desc:before,
.SortableTable .-sort-asc:before {
	position: absolute;
	font-weight: 500;
	font-size: 18px;
	right: 4px;
	top: 2px;
}

.SortableTable .-sort-desc:before {
	content: "↓";
}
.SortableTable .-sort-asc:before {
	content: "↑";
}
