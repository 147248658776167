.SortableList {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 0;
	list-style: none;
}

.SortableListContainer {
	background-color: var(--lw-light-grey-c);
	padding: 10px;

	/* Bootstrap css sets this and I don't want it */
	ol,
	ul {
		margin-top: 0;
		margin-bottom: 0;
	}
}
