.App {
	text-align: left;
	height: 100vh;
	display: flex;
	flex-direction: column;
	min-height: 0px;
}
.UnderHeader {
	position: relative;
	display: flex;
	flex-direction: row;
	flex: 1;
	min-height: 0px;
}
.hoverPointer:hover {
	cursor: pointer;
	user-select: none;
}

.allowUnderHeaderOverflow .UnderHeader {
	min-height: auto;
}

.GlobalPagination {
	display: flex;
}
.allowUnderHeaderOverflow.forcePaginationVisible .GlobalPagination {
	display: flex;
}
.allowUnderHeaderOverflow .GlobalPagination {
	display: none;
}

.UnderSubHeader {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 0px;
}

.UnderSubHeader > div {
	margin: 12px;
}

.UnderSubHeader > div.noDefaultMargin {
	margin: 0px;
}

.UnderSubHeader > div.searchFullHeightDefault {
	height: 100%;
}

.UnderSubHeader > div.searchFullHeightDefault > div {
	height: 100%;
	display: flex;
	align-items: stretch;
	flex-direction: column;
}

.App-content {
	background-color: white;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	flex: 1 1;
	color: var(--lw-dark-grey);
	height: 100%;
	min-height: 0px;
}

.App-link {
	color: #61dafb;
}
.ColumnLeft,
.ColumnRight {
	float: left;
	width: 50%;
	padding: 12px;
	min-width: 220px;
	max-width: 400px;
}
.narrowScreenButtonGroup {
	display: none;
	@media (max-width: 999px) {
		display: flex;
		margin-left: auto;
		margin-top: 0;
		margin-right: 16px;
		display: flex;
		height: 100%; /* this prevents excess space below the buttons so the dropdowns always stay directly below the buttons */
		min-width: fit-content;
		align-items: center;
	}
}
.documentPropertiesButtonGroup {
	display: none;
	@media (min-width: 1000px) {
		margin-left: auto;
		margin-top: 0;
		margin-bottom: 8px;
		margin-right: 16px;
		gap: 8px;
		display: flex;
		height: min-content; /* this prevents excess space below the buttons so the dropdowns always stay directly below the buttons */
		min-width: fit-content;
		.ButtonInner {
			display: flex;
			align-items: center;
		}
	}
}
.buttonGroup {
	margin-left: auto;
	margin-top: 0;
	margin-bottom: 8px;
	margin-right: 16px;
	display: flex;
	height: min-content; /* this prevents excess space below the buttons so the dropdowns always stay directly below the buttons */
	min-width: fit-content;
	.ButtonInner {
		display: flex;
		align-items: center;
	}
}
.buttonGroup > *:not(:last-child) {
	margin-right: 8px;
}
.center300 {
	width: 300px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.center332 {
	width: 332px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.center450 {
	width: 450px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.center600 {
	width: 600px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.center50pct {
	width: 50%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.inlineErrorIndicator {
	padding: 2px 6px;
	background-color: var(--lw-error);
	color: white;
	border-radius: 8px;
	margin: 4px 0;
}
.inlineInfoMessage {
	padding: 2px 6px;
	background-color: var(--lw-info);
	color: white;
	border-radius: 8px;
	margin: 4px 0;
}
.centeredH2 {
	font-family: "MainFont-Light";
	text-align: center;
	margin: 64px auto;
}
.noselect {
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;

	-ms-user-select: none;
	user-select: none;
}

.autoResizeTableContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
}
.fullHeightFlex {
	display: flex;
	flex: 1;
	flex-direction: column;
	min-height: 0px;
}
.spaceBetween {
	justify-content: space-between;
}
.scrollbar-measure {
	width: 100px;
	height: 100px;
	overflow: scroll;
	position: absolute;
	top: -9999px;
}
.MainFontBold {
	font-family: "MainFont-Bold";
}
.MainFontSemiBold {
	font-family: "MainFont-SemiBold";
}
.MainFontLight {
	font-family: "MainFont-Light";
}
.tagPill {
	width: 100px;
	height: 80px;
	background-color: var(--lw-dark-blue);
	display: inline;
	margin-left: 5px;
	padding: 2px 6px 2px 6px;
	font-size: 13px;
	border-radius: 14px;
	overflow: Hidden;
	color: white;
}
.legalworks-clio-logo {
	padding-right: 6px;
	height: 18px;
	width: auto;
}

.ReactModalPortal .slide-pane__overlay {
	z-index: 101;
}
.ReactModalPortal .previewPaneOverlay {
	top: 72px;
}

.ReactModalPortal .slide-pane__content {
	padding: 0px;
	overflow-x: hidden;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
}

.preview-header {
	height: 50px;
	width: 900px;
	background-color: #ebebeb;
	top: 0;
	position: sticky;
	display: flex;
}
.preview-header-left {
	padding: 15px;
	padding-right: 0px;
	flex-grow: 2;
	white-space: nowrap;
	overflow: hidden;
}
.preview-header-left > div {
	overflow: hidden;
	text-overflow: ellipsis;
}
.preview-header-right {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
	text-align: right;
}
.preview-close {
	float: right;
	margin-right: 35px;
	margin-top: 10px;
	margin-left: 17px;
	padding: 5px;
}
.preview-tooltip {
	margin-top: 3px;
	margin-left: 0px;
	padding: 12px;
}
.preview-properties {
	margin-top: 12px;
	margin-left: 10px;
	padding: 3px;
}
.preview-hover:hover {
	cursor: pointer;
}
.preview-text {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: pre-line;
	line-height: 23px;
}
.preview-content {
	color: var(--lw-darker-grey);
	padding: 16px;
	flex-grow: 1;
	overflow-y: scroll;
}
.preview-image {
	height: 535px;
	width: 900px;
}
.preview-image img {
	display: block;
	max-width: 95%;
	max-height: 100%;
	min-width: 40%;
	padding-top: 20px;
	padding-bottom: 20px;
	margin: auto;
}
.previewOpenDocumentContainer {
	height: 500px;
}
.previewOpenDocumentCenter {
	position: absolute;
	top: 50%;
	width: 100%;
	text-align: center;
}
.documentPreviewFavoriteStar {
	color: var(--lw-gold);
}
.documentPreviewNotFavoriteStar {
	opacity: 1;
}
.preview-icon-box {
	display: flex;
	flex-direction: row-reverse;
	gap: 8px;
	line-height: 48px;
	padding-right: 8px;
}
.preview-icon-box .preview-buttons {
	display: flex;
	gap: 8px;
}
.preview-icon-box button.MuiButtonBase-root {
	min-width: 40px;
	height: 40px;
}

.TableButton {
	width: 24px;
}

.TableButton .MuiButton-root {
	min-width: initial;
	position: absolute;
	top: -2px;
}

.emailPreviewInfoContainer {
	height: 85px;
	padding-top: 7px;
	padding-left: 22px;
	border-bottom: 1px solid #e2e2e2;
}
.emailPreviewInfoLabel {
	display: block;
	width: 5em;
	float: left;
	clear: left;
}
.emailPreviewInfoValue {
	float: left;
	max-width: 85%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.GreyContainer {
	width: 100%;
	border: 1px solid #dddddd;
	padding: 20px;
}
.ShareFooter {
	color: #777777;
	font-size: 12px;
}
.shareFileListItem {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.globalSearchboxOption {
	line-height: 24px;
}
.globalSearchboxOption > div {
	padding-left: 24px;
}
.globalSearchboxOption svg {
	top: 12px;
	left: 4px;
}
.globalSearchboxOption .searchboxActionPill {
	font-size: 12px;
	line-height: 18px;
	display: none;
	padding: 4px;
}
.globalSearchboxOption:hover .searchboxActionPill {
	display: block;
}
.DropDownSelect .globalSearchbox__menu-list {
	max-height: 360px;
}
.MuiTypography-root {
	/* overrides the default MUI font */
	font-family: "Poppins" !important;
}
.UnderSubHeader .rt-noData {
	background: none;
	color: rgb(81, 81, 81);
}
