* {
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
*:focus {
	outline: 0;
}
html {
	font-size: 16px;
}
body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
@font-face {
	font-family: "OpenSans";
	src:
		local("OpenSans"),
		url(./fonts/Open_Sans/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "OpenSans-Light";
	src:
		local("OpenSans-Light"),
		url(./fonts/Open_Sans/OpenSans-Light.ttf) format("truetype");
}
@font-face {
	font-family: "OpenSans-Bold";
	src:
		local("OpenSans-Bold"),
		url(./fonts/Open_Sans/OpenSans-Bold.ttf) format("truetype");
}
@font-face {
	font-family: "OpenSans-SemiBold";
	src:
		local("OpenSans-SemiBold"),
		url(./fonts/Open_Sans/OpenSans-SemiBold.ttf) format("truetype");
}
@font-face {
	font-family: "MainFont-Italic";
	src:
		local("Poppins-Italic"),
		url(./fonts/Poppins/Poppins-Italic.ttf) format("truetype");
}

@font-face {
	font-family: "Poppins";
	src:
		local("Poppins"),
		url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}
@font-face {
	font-family: "Poppins-Bold";
	src:
		local("Poppins-Bold"),
		url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
	font-family: "Poppins-SemiBold";
	src:
		local("Poppins-SemiBold"),
		url(./fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
	font-family: "MainFont";
	src:
		local("Poppins-Regular"),
		url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}
@font-face {
	font-family: "MainFont-Bold";
	src:
		local("Poppins-Bold"),
		url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
@font-face {
	font-family: "MainFont-SemiBold";
	src:
		local("Poppins-Medium"),
		url(./fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}
@font-face {
	font-family: "MainFont-Light";
	src:
		local("Poppins-Light"),
		url(./fonts/Poppins/Poppins-Light.ttf) format("truetype");
}
