.HeaderBar {
	height: 72px;
	line-height: 64px;
	background-color: var(--lw-blue);
	display: flex;
	border-bottom: 8px solid var(--lw-light-blue);
	min-width: fit-content; /* This is to prevent the HeaderBar from hiding content when the page width is reduced. May cause the horizontal scroll bar to appear */
}
.HeaderBar > div {
	text-align: center;
	height: inherit;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 13px;
}
.HeaderBar a {
	color: white;
	height: 100%;
	width: auto;
}
.legalworks-logo {
	height: 58%;
	width: auto;
}
.legalworks-logo:hover {
	cursor: pointer;
}
.HeaderBar a > svg {
	position: relative;
}
.HeaderBar #HeaderNewDropdown {
	margin-left: 30px;
}
.HeaderUserDropDown button span {
	font-size: 1em;
	margin-right: 0px;
}
.DropDownButton.HeaderUserDropDown.IconDropDown button > svg {
	margin: 2px auto;
}
.HeaderBar .headerLeftButtons {
	position: relative;
	top: -3px;
	text-align: center;
	height: inherit;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 13px;
}
