.TabLookingRadioButtons {
	padding-top: 10px;
	font-size: 1.2em;
}
.TabLookingRadioButtons * {
}
.TabLookingRadioButtonsElement.TLRB-Active {
	border-bottom: 5px solid;
	border-color: var(--lw-blue);
}
.TabLookingRadioButtonsElement {
	padding-left: 10px;
	padding-right: 10px;
}
.TabLookingRadioButtonsElement:hover {
	cursor: pointer;
}
