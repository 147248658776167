.Table__itemCount {
	/* margin-top: 10px; */
	font-size: 14px;
}

.Table__pagination {
	display: flex;
	justify-content: flex-end;
	padding: 20px 10px;
}

.Table__pageButton {
	font-size: 15px;
	outline: none;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.Table__pageButton:disabled {
	cursor: not-allowed;
	color: gray;
}

.Table__pageButton--active {
	color: #45b3e3;
	font-weight: bold;
}
