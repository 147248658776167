.SubHeaderContainer {
	width: 100%;
	background-color: var(--lw-light-grey-a);
	border-bottom: 1px solid;
	border-color: var(--lw-dark-grey);
	height: 64px;
	display: flex;
}
.SubHeader {
	padding-top: 20px;
	padding-bottom: 20px;
	align-items: center;
	display: flex;
}
.SubHeader .SubheaderTitle {
	font-family: "MainFont-SemiBold";
}
.SubHeader > div {
	color: var(--lw-dark-grey);
	font-size: 22px;
}
.SubHeader > div .TrademarkSymbol {
	font-family: "MainFont";
}
