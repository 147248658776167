em {
	font-family: "MainFont-Bold";
	background-color: var(--lw-gold);
}

table,
th,
td {
	padding: 0 8px 0 4px;
}

.searchTagTypeWrapper {
	display: flex;
	align-items: center;
}

.searchTagPill {
	background: #e6e6e6;
	padding-top: 1px;
	padding-bottom: 1px;
	padding-left: 3px;
	padding-right: 3px;
	margin: 4px;
	border-radius: 2px;
	max-width: 300px;
	text-overflow: ellipsis;
	overflow: hidden;
}
