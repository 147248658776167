.snackbarProgressSpinner .MuiCircularProgress-circle {
	stroke: white;
}

.snackbarProgressSpinner {
	margin-right: 16px;
}

/* when multiple snackbars are active this prevents them from stacking */
/* if adding a new snackbarKey be sure to add a new class here for the next child */
/* bottom formula is 24 + ((n-1)*56) */
.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft.snackbarSpacer:nth-child(
		2
	) {
	bottom: 80px; /* 24 + ((2-1)*56) */
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft.snackbarSpacer:nth-child(
		3
	) {
	bottom: 136px; /* 24 + ((3-1)*56) */
}
