.ButtonWithIcon {
	cursor: pointer;
}

.ButtonWithIcon {
	/*border-radius: 4px;
	text-align: left;
	text-decoration: none;
	color: var(--lw-dark-grey);
	background-color: white;
	border: 1px solid black;
	line-height: 1em;*/
	position: relative;
	display: inline-block;
	overflow: hidden;
	height: 32px;
}
.ButtonWithIcon.disabled * {
	color: var(--lw-medium-grey-b);
}
.ButtonWithIcon.disabled {
	border-color: var(--lw-medium-grey-b);
	background-color: var(--lw-light-grey-c);
}
.ButtonWithIcon.disabled:hover {
	text-decoration: none;
	cursor: not-allowed;
	background-color: var(--lw-light-grey-c);
}
.ButtonWithIcon.disabled input {
	cursor: not-allowed;
}
.ButtonWithIcon.blue {
	color: white;
	background-color: var(--lw-light-blue);
}

.ButtonWithIcon:hover {
	text-decoration: none;
	background-color: var(--lw-light-blue);
	color: white;
	cursor: pointer;
}
.ButtonWithIcon.blue:hover {
	background-color: var(--lw-blue);
	color: white;
}
.ButtonWithIcon:active {
	text-decoration: none;
	background-color: var(--lw-blue);
}
.ButtonWithIcon input[type="file"] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}
.ButtonWithIcon input[type="file"]:hover {
	cursor: pointer;
}
.ButtonWithIcon .ButtonInner {
	display: grid;
	grid-template-columns: 1fr 4fr;
	padding: 8px;
}
.ButtonWithIcon .ButtonText {
	font-size: 1.2em;
	margin-left: 0.5em;
}
.ButtonWithIcon.IconOnly {
	height: inherit;
}
.ButtonWithIcon.IconOnly {
	background-color: rgba(0, 0, 0, 0);
	border: none;
}
.ButtonWithIcon.Favorite:hover {
	background-color: rgba(0, 0, 0, 0);
}
.ButtonWithIcon.Favorite:hover .ButtonText {
	color: var(--lw-dark-grey);
}
.ButtonWithIcon.Favorite {
	border: none;
	height: inherit;
}
.Favorite svg {
	font-size: 1.2em;
}
.Favorite.notFav svg {
	color: black;
}
.Favorite.isFav svg {
	color: var(--lw-gold);
}
.ButtonWithIcon.Favorite .ButtonInner {
	grid-template-columns: 1fr 2fr;
}
.ButtonWithIcon.Favorite .ButtonText {
	line-height: 28px;
}
.ButtonWithIcon.IconOnly svg.fa-1x,
.ButtonWithIcon.Favorite svg.fa-1x {
	font-size: 2em;
}
