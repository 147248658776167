.DropDownButton button {
	min-width: 100px;
	/*height: 32px;*/
	/*border-color: black;*/
	line-height: 1.2em;
}
.DropDownButton.RegularDropDown button {
	box-shadow:
		0px 1px 5px 0px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	background: var(--lw-light-grey-a);
	padding: 6px 16px;

	min-width: 64px;
	border: 0px;
	box-sizing: border-box;
	transition:
		background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	line-height: 1.75;
	font-weight: 500;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	border-radius: 4px;
}
.DropDownButton.RegularDropDown button:hover {
	background-color: #d5d5d5;
}

.DropDownButton.RegularDropDown button span {
	line-height: 1.75;
	font-size: 0.875rem;
	font-weight: 500;
	height: initial;
}

.DropDownButton button > * {
	margin-right: 8px;
	font-size: 1.2em;
}
.DropDownButton.IconDropDown {
	height: inherit;
}
.DropDownButton.IconDropDown button.btn-default {
	color: white;
	background-color: rgba(0, 0, 0, 0);
	border: none;
	height: inherit;
	top: -2px;
}
.DropDownButton.IconDropDown button > svg {
	display: block;
	margin: 0px auto;
	font-size: 2em;
}
.DropDownButton.IconDropDown .caret {
	display: none;
}

.DropDownButton .dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:focus,
.dropdown-menu > .disabled > a:hover {
	color: #aaa;
}
.DropDownButton ul.dropdown-menu {
	z-index: 10000;
}
.goldButton .DropDownButton button {
	background-color: var(--lw-gold);
}

.dropdown-menu input[type="file"] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0.5;
}

.DropDownButton.HollowButton button,
.DropDownButton.HollowButton button:hover,
.HollowButton button:active:focus,
.HollowButton button:active:hover,
.HollowButton button:active {
	border: none;
	box-shadow: none;
	background: transparent;
	color: white;
}

.DropDownButton.HollowButton button span {
	text-decoration: underline;
	font-size: 1rem;
}

.WhatsNewDropdown.BellIsRinging > button > svg {
	animation: ring 4s 0.7s ease-in-out infinite;
}
.WhatsNewDropdown .WhatsNewItem a {
	color: black;
}
.WhatsNewDropdown div.WhatsNewItem:hover {
	background: #f5f5f5;
}
.WhatsNewItem a:hover,
.WhatsNewItem a:active,
.WhatsNewItem a:visited {
	text-decoration: none;
}

@keyframes ring {
	0% {
		transform: rotate(0);
	}
	1% {
		transform: rotate(30deg);
	}
	3% {
		transform: rotate(-28deg);
	}
	5% {
		transform: rotate(34deg);
	}
	7% {
		transform: rotate(-32deg);
	}
	9% {
		transform: rotate(30deg);
	}
	11% {
		transform: rotate(-28deg);
	}
	13% {
		transform: rotate(26deg);
	}
	15% {
		transform: rotate(-24deg);
	}
	17% {
		transform: rotate(22deg);
	}
	19% {
		transform: rotate(-20deg);
	}
	21% {
		transform: rotate(18deg);
	}
	23% {
		transform: rotate(-16deg);
	}
	25% {
		transform: rotate(14deg);
	}
	27% {
		transform: rotate(-12deg);
	}
	29% {
		transform: rotate(10deg);
	}
	31% {
		transform: rotate(-8deg);
	}
	33% {
		transform: rotate(6deg);
	}
	35% {
		transform: rotate(-4deg);
	}
	37% {
		transform: rotate(2deg);
	}
	39% {
		transform: rotate(-1deg);
	}
	41% {
		transform: rotate(1deg);
	}

	43% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}
