.TextGrid {
	display: grid;
}
.TextGrid .TextGridColumn {
	display: grid;
	grid-template-columns: 2fr 3fr;
}
.TextGrid .TextGridColumn .TextGridElement:first-child {
	font-family: "MainFont-Bold";
}
.TextGrid .TextGridElement {
	white-space: pre-line;
	word-break: break-word;
}
