.LeftSidebar.sidenav---sidenav---_2tBP {
	background-color: var(--lw-darker-grey);
	position: relative;
	z-index: 150;
	min-height: fit-content; /* if DPI was very high users were not seeing all of the sidebar */
}
.sidenav---sidenav-nav---3tvij
	> .sidenav---sidenav-navitem---uwIJ-
	> .sidenav---navitem---9uL5T
	.sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
	> .sidenav---sidenav-navitem---uwIJ-
	> .sidenav---navitem---9uL5T
	.sidenav---navicon---3gCRo
	> *,
.sidenav---sidenav-nav---3tvij
	> .sidenav---sidenav-navitem---uwIJ-
	> .sidenav---navitem---9uL5T
	.sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij
	> .sidenav---sidenav-navitem---uwIJ-
	> .sidenav---navitem---9uL5T
	.sidenav---navtext---1AE_f
	> *,
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
	.sidenav---sidenav-subnav---1EN61
	> .sidenav---sidenav-subnavitem---1cD47
	> .sidenav---navitem---9uL5T,
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
	.sidenav---sidenav-subnav---1EN61
	> .sidenav---sidenav-subnavitem---1cD47
	> .sidenav---navitem---9uL5T
	* {
	color: white;
}
.sidenav---sidenav-subnav---1EN61
	> .sidenav---sidenav-subnavitem---1cD47
	> .sidenav---navitem---9uL5T {
	display: flex;
}

.LeftSidebar svg {
	font-size: 1.2em;
}

.LeftSidebar .NavText {
	margin-left: 8px;
}
.LeftSidebar .sidenav---sidenav-subnav---1EN61 .NavText {
	margin-left: 35px;
}
.LeftSidebar .sidenav---sidenav-subnav---1EN61 {
	min-width: 300px;
}
.LeftSidebar
	.sidenav---sidenav-subnav---1EN61
	> .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y
	> .sidenav---navitem---9uL5T {
	color: #333;
	font-weight: normal;
	background: none;
	cursor: default;
}

.LeftSidebar
	.sidenav---sidenav-subnav---1EN61
	> .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y
	> .sidenav---navitem---9uL5T::before {
	display: none;
}
.LeftSidebar
	.sidenav---sidenav-nav---3tvij
	> .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u
	> .sidenav---navitem---9uL5T::after,
.LeftSidebar
	.sidenav---sidenav-nav---3tvij
	> .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u
	> .sidenav---navitem---9uL5T::after {
	opacity: 0.2;
	background: var(--lw-medium-grey-b);
}
